const language = {
    // English
    en:
    {
        languageNo: 6,
        languageName: 'English',
        flag:'gb',
        theGrolmanGroup: 'The Grolman Group',
        internationalDistributionOfSpecialtyChemicals:'International Distribution of Specialty Chemicals',
        personalCare: 'Personal Care',
        industry: 'Industry',
        imprint: 'Imprint',
        privacy: 'Privacy',
        msdsDownload: 'MSDS Download',
        msds: 'Material Safety Data Sheets',
        contact: 'Contact',
        downloads: 'Downloads',
        resources: 'Resources',
        msdsDownloadSentence: 'Please find our Material Safety Data Sheet (MSDS) documents at this URL:',
        certificate: 'Certificate',
        emailCertificates: 'E-Mail Certificates',
        emailCertificatesSentence: 'To verify the validity of personal Grolman e-mail certificates, we provide the Grolman root certification authority ("CA") certificate for download here.',
        fingerprint: 'Fingerprint',
        termsAndConditions: 'Terms & Conditions',
        termsAndConditionsFooter: '<span class="whiteSpaceNoWrap">Terms &</span> Conditions',
        homeStartText: 'The Grolman Group operates an international specialty chemicals distribution business. It is composed of individual local sales offices based in all European countries offering technically trained sales staff, customer service teams and local warehousing. The Grolman Group run by the fifth generation of the Grolman family has been privately owned since it was established in 1855. The key to its success has been the dedication and commitment to building an efficient customer-focused organization where customers’ needs drive our business.',
        companyText1:'The Grolman Group operates an international specialty chemicals distribution business. It is composed of individual local sales offices based in all European countries, Turkey, Maghreb, Egypt, India and China, each supported by technically trained sales staff, customer service teams and local warehousing.',
        companyText2:'The Grolman Group, run by the fifth generation of the Grolman family, has been privately owned since it was established in 1855. The key to its success has been the dedication and commitment to building an efficient customer-focused organization where customers’ needs are an essential driving force.',   
        grolmanQualitySince1855:'Grolman. Quality since 1855.',
        partner: 'Partner',
        meetUs: 'Meet us',
        exhibitions: 'Meet us',
        meetUsHeaderText: 'Meet us',
        locations: 'Locations',
        selectLocation: 'Select location',
        selectLanguage: 'Select language',
        selectCategory: 'Select category',
        pleaseSelectACategory: 'Please select a category',
        productsText: 'Speciality chemicals for the paints, coatings, building, adhesives, sealant, rubber, plastics, pharma, cosmetics and paper industry.',
        industriesText: 'Resins, additives, pigments and fillers from one source.',
        vatRegistrationNumber: 'VAT Registration Number',
        registrationOffice: 'Registration Office',
        taxRegistrationNumber: 'Tax Registration Number',
        surfaceCoatings: 'Paints & Coatings',
        adhesivesAndSealants: 'Adhesives & Sealants',
        adhesives: 'Adhesives',
        printingInk: 'Printing Inks & Graphic Arts',
        paper: 'Paper',
        agriculture: 'Agriculture',
        construction: 'Construction',
        plastics: 'Plastics',
        rubber: 'Rubber',
        sealants: 'Sealants',
        pharma: 'Pharma',
        miscellaneous: 'Miscellaneous',
        moreIndustries: 'More Industries',
        battery: 'Battery',
        pleaseSelectACountryForMoreInformation: 'Please select a country for more information.',
        producer: 'Producer',
        tradeName: 'Trade name',
        description: 'Description',
        applications: 'Applications',
        marketSegments: 'Market Segments',
        forMoreDetailsReferToOurCountryPages: 'For details please refer to our country pages.',
        noFilesAvailable: 'No files available',
        managingDirectors: 'Managing directors',
        generalManager: 'General Manager',
        editor: 'Editor',
        theDataProtectionOfficerIsProvidedBy: 'The Data Protection Officer is provided by',
        legalInformation:'Legal Information',
        meetUsOnOurStand:'Meet us at our stand',
        meetUsAt:'Meet us at the',
        meetUsIn:'Meet us in',
        stand:'stand',
        hall: 'hall',
        meetUsDigitally: 'Meet us digitally',
        externalLink:'External Link',
        managingDirectorFemale: 'Managing Director',
        january: 'January',
        february: 'February',
        march: 'March',
        april: 'April',
        may: 'May',
        june: 'June',
        july: 'July',
        august: 'August',
        september: 'September',
        october: 'October',
        november: 'November',
        december: 'December',
        postponed: 'POSTPONED',
        moreInfo: 'More Information',
        noFurtherInformationAvailable: 'For further information regarding our additives, resins, pigments, dyes, fillers and performance materials, please contact our local team under ',
        worthWhileChat: 'Contact us to join our worth.while.chats. A new interactive online format on exciting topics',
        onlineSeminars: 'Contact us to join our online seminars with suppliers.',
        theGrolmanGroup: 'The Grolman Group',
        stockAppointmentBooking: 'Warehouse Deliveries & Collection Booking',
        stockAppointmentBookingSentence: 'Book a slot for a delivery or collection time from our Neuss warehouse with this link:',
        integrityNextProfile: 'Integrity Next Profile',
        integrityNextProfileSentencePart1: 'Check out our',
        integrityNextProfileSentencePart2: 'Sustainability Profile on Integrity Next',
        integrityNextProfileSentencePart3: 'to get a quick overview of our sustainability performance.',
        rspoMembership: 'RSPO Membership',
        rspoMembershipSentence: 'Grolman International Distribution is an Ordinary Member of RSPO (Roundtable on Sustainable Palm Oil) and holds a Distributor License. RSPO is an international non-profit organisation formed in 2004 with the objective to promote the growth and use of sustainable oil palm products through credible global standards and engagement of stakeholders. Grolman International Distribution confirms the implementation of the RSPO Shared Responsibility requirements in its palm oil related operations and shows its compliance through its Annual Communication On Progress (ACOP) and its MyRSPO membership profile. Moreover, Grolman International Distribution has a sustainable management system that covers the RSPO Shared Responsibility requirements.',
        rspoDistributorLicense: 'RSPO Distributor License',
		womensAndEqualOpportunitiesOfficer: 'Women\'s and Equal Opportunities Officer',
        noProductsToDisplay: "We don't have any products to display in this category at the moment",
        codeOfConduct: "Code of Conduct",
        sustainabilityReport: "Sustainability Report",
        sustainabilityReporting: "Sustainability Reporting",
        policyDocuments: "Policy Documents",
        policyDocumentsSentence: "Please find our policy documents here",
        supplierAssessment: "Supplier Assessment",
        conflictMineralsPolicy: "Conflict Minerals Policy",
        whistleblowerPolicy: "Whistleblower Policy",
        shePolicy: "SHE Policy",
        environmentalPolicyAndSustainabilityStandards: "Environmental Policy and Sustainability Standards",
        positionOnHumanRightsDiversityAndInclusion: "Position on Human Rights, Diversity and Inclusion",
        antiCorruptionAndAntiBriberyPolicy: "Anti Corruption and Anti Bribery Policy",
        sustainabilityReportSentence: "We invite you to explore our Sustainability Reports, highlighting our ongoing efforts to create a positive impact. Discover how we're embracing sustainability across our operations and contributing to a greener, more inclusive future for all.",
        complianceForm: "Compliance Form",
        codeOfConductSentence: "To know more about our company vision and how we conduct business please download our Code of Conduct and business ethics below.",
        sustainabilityStatement: "Sustainability Statement",
        sustainabilityStatementSentence: "Please find here our sustainability statement emphasising our commitment to Environmental, Social and Corporate Governance (ESG) principles and sharing important information & documents illustrating our ambition.",
        whistleblower: 'Whistle-blower-system',
        introduction: 'Introduction',
        whistleblowerIntroductionSentence: 'Gustav Grolman GmbH & Co. KG and all its international distribution subsidiaries, hereinafter referred to as "Grolman" or "Grolman international Distribution", are committed to conducting business in an ethical and honest manner and to implementing and enforcing a whistle blower system to ensure that all its business activities are conducted in such matter.',
        purpose: 'Purpose',
        whistleblowerPurposeSentence: 'This policy provides a framework for whistle-blower protection, third-party software reporting, the investigation process, and the various roles and responsibilities within that process. This policy provides information about the software through which suspected unlawful activity may be reported.',
        actionsAndResponsibilities: 'Actions and Responsibilities',
        whistleblowerActionsAndResponsibilitiesSentence: 'For that purpose Grolman International Distribution senior management took the action and responsibility to invest in a world renowned whistle blowing system operated by a third party Grant Thornton; ',
        distributionIn: 'Distribution in',
        search: 'Search',
        links: 'Links',
        productGroups: 'Product Groups',
        contacts: 'Contacts',
        menu: {
            home: 'Home',
            company: 'Company',
            products: 'Products',
            industries: 'Industries',
            facts: 'Profile',
            lab: 'Laboratory',
            jobs: 'Jobs',
            visionAndSustainability: 'Vision & Sustainability',
            history:'History',
        },
        jobsSection: {
            headerText: 'Here you can find our open job advertisements.',
            noJobsAvailablePart1: 'Hello there! Sorry, this page is empty at the moment, as we don\'t have any vacancies right now. But why don\'t you contact us anyway at',
            noJobsAvailablePart2: 'and let us know why you would like to join our team! We\'re looking forward to getting to know you!',
            footerText: 'If you are interested in this position, please send your application documents to ',
            findDetailsInPDF: 'Further details about this position can be found in this PDF document',
            fullTime: 'full-time',
            preferablyFullTime: 'preferably full-time',
            mf: '(m/f)',
            mfd: '(m/f/d)',
        },
        optOut: {
            dataPrivacyInformation: 'Data privacy information',
            tracking: 'Tracking',
            weUseCookiesNote: 'We use cookies to enable you to make the best possible use of our website. Cookies necessary for operating the website are set. You can also allow cookies for statistics purposes. You can change privacy settings or directly allow all cookies.',
            changePrivacySettings: 'Change privacy settings',
            acceptAllCookies: 'Yes, I accept all cookies',
            matomoCookieAndTrackingExplanation: 'We use the open source software tool Matomo on our website. Matomo does not transfer any data to servers outside the control of Gustav Grolman GmbH & Co. KG. The tool uses cookies that allow us to count the number of visits. These text files are saved on your computer and enable the Gustav Grolman GmbH & Co. KG to analyse how its website is used. For us, your IP address is an anonymous code. This means we have no technical means of identifying you as a registered user. You will remain anonymous as a user. If you agree to the analysis of your data, please activate this cookie.',
            acceptChanges: 'Save changes',
            allowAll: 'Allow all',
            linkToPrivacyStatement: 'Click here for the privacy policy',
            allowTracking: 'Allow Tracking',
        },
        privacyTexts: {
            privacyStatement: 'Privacy Statement',
            whenYouVisitOurWebsite: 'When you visit our website, we process personal data. We would like to inform you about this and your rights here.',
            dataProcessingWhenUsingThisSite: 'Data processing when using this website',
            weProcessData: 'We process your personal data as follows:',
            serverLogFiles: 'Server Log Files',
            automaticallyProcessesInformationProvidedByBrowser: 'The provider of the websites automatically processes information provided by your browser automatically, known as server log files. These include:',
            browserTypeAndVersion: 'Browser type and version',
            operatingSystem: 'Operating system used',
            referrerUrl: 'Referrer URL (address of origin)',
            dateAndTimeOfServerRequest: 'Date and time of server request',
            ipAddress: 'IP address',
            serverLogFilesText1: 'This data is stored separately from other data that you may transmit to us and is not merged with data from other sources.',
            serverLogFilesText2: 'The legal basis for the data processing is Art. 6 Para. 1 Letter f GDPR, which permits the processing of data on the basis of legitimate interest. In this case, there is a legitimate interest in the secure and uninhibited operation of the web server. To ensure this, the administration must be able to detect and track attacks and malfunctions of the system via server log files, and to recognise attack patterns, accesses to the server must be saved. As soon as this data is no longer needed it is deleted. For technical reasons, the data is available to the hosting service provider, who is, however, bound by our instructions and contractually obligated to us.',
            hereYouCanGiveOrRevokeCookieConsent: 'Here you can give or revoke your consent:',
            giveOrRevokeConsent: 'Give or revoke consent',
            furtherDataProcessingInInternetPresencesOnSocialNetworks: 'Further data processing in internet presences on social networks',
            socialNetworksText1: 'We are present in social networks and operate websites there. To communicate with the users active there and to offer information about us, we process data about the users of these networks.',
            socialNetworksText2: 'We would like to point out that user data may be processed outside the European Union and the European Economic Area. This may result in risks for users, for example because it could be more difficult for them to enforce their rights.',
            socialNetworksText3: 'As a rule, social networks also process user data for the purposes of market research and advertising. For example, user profiles can be created based on user behaviour. These can be used to place advertisements within and outside the networks that correspond to the presumed interests of the users.',
            socialNetworksText4: 'The usage profile can be stored in cookies that are stored in the user\'s terminal device. Storage can also take place directly in the social network, especially if the users are members of the respective platforms and logged in there.',
            socialNetworksText5: 'Types of data that are processed include, in particular, contact data (e.g., e-mail, telephone number), content data (e.g., information entered in forms), usage data (e.g., websites visited, interest in content, access times), meta and communication data (e.g., device information, IP addresses).',
            socialNetworksText6: 'The data subjects are the users of the websites and networks.',
            socialNetworksText7: 'The purposes of the data processing are the handling of contact requests and communication, the collection of feedback and marketing.',
            socialNetworksText8: 'The legal basis for the data processing is our legitimate interests in communication and marketing (Art. 6 para. 1 lit. f. DSGVO).',
            socialNetworksText9: 'For a detailed presentation of the respective data processing and the possibilities to object (i.e., to opt-out), please refer to the data protection information of the operators of the respective networks. Requests for information and the assertion of user rights can be asserted most effectively with the respective operator; we can provide support in this regard upon request.',
            furtherInformationAboutSocialNetworks: 'Further information about social networks:',
            yourRights: 'Your rights',
            yourRightsText1: 'As the data subject you have the following rights, provided the legal conditions are fulfilled:',
            right1: 'Right to information, Art. 15 GDPR',
            right2: 'Right to Rectification of incorrect data, Art. 16 GDPR',
            right3: 'Right to Erasure of Data, Art. 17 GDPR',
            right4: 'Right to Restriction of Processing, Art. 18 GDPR',
            right5: 'Right to Data Portability, Art 20. GDPR',
            right6: 'Right to Objection, Art. 21 GDPR',
            yourRightsText2: 'Provided the data processing is based on your consent, you may revoke it at any time, with effect on the future.', 
            yourRightsText3: 'You also have the right to launch a complaint with the Data Protection Supervisory Authority about the data processing.',
            responsibilityAndDataProtectionOfficer: 'Responsibility and Data Protection Officer',
            controllerOfProcessingPersonalData: 'The controller for the processing of your personal data on this website is:',
            dataProtectionOfficerFoxOn: 'The external Data Protection Officer can be reached at: fox-on Datenschutz GmbH, Pollerhofstr. 33a, 51789 Lindlar, Germany, email: dsb@fox-on.com.',
            dataProtectionOfficerThomasSchlueter: 'The external Data Protection Officer can be reached at: Dr. Thomas Schlüter, Warendorfer Strasse 60, 48145 Münster, Germany, email: datasecurity@grolman-group.com.',
            cookies: {
                cookies: 'Cookies',
                cookiesText1: 'The website uses cookies. These are data records that are stored on your computer and can be evaluated. They are necessary in order to provide you with the web pages.',
                cookiesText2: 'You will find more details on the cookies used in the respective sections of this Notice.',
                cookiesText3: 'In addition, the following cookies are used:',
                cookieConsent1: 'Consent to tracking: Whether or not you have consented to the use of tracking is stored in a session cookie.',
                cookieConsent2: 'Session cookies: In order to be able to assign your actions during a session, further cookies can be created. They are automatically deleted when the browser is closed. They are not used to evaluate your user behaviour.',  
                cookiesText4: 'These cookies are technically necessary and are processed on the basis of § 25 Para. 2 No. 2 TTDSG (German Telecommunications Telemedia Data Protection Act). We have a legitimate interest in the technically error-free and optimised provision of our services.',
                cookiesText5: 'You can set your browser so that you are informed about the setting of cookies and only allow cookies in individual cases, exclude the acceptance of cookies for certain cases or in general and activate the automatic deletion of cookies when closing the browser. If you deactivate cookies, the functionality of these internet pages may be limited.',
            },
            matomo: {
                matomo: 'Matomo',
                matomoText1: 'This website uses the web analysis service Matomo to analyse and regularly improve the use of the website. The statistics obtained will be used to improve the site and make it more interesting for you as a user.',
                matomoText2: 'The information that Matomo collects about your use of this website is stored on our server (such as pages viewed, date and time of request, referrer URL (previously visited page), browser type and version, operating system used). Your IP address is anonymised before storage.',
                matomoText3: 'The information generated about the use of this website is not passed on to third parties.',
                matomoText4: 'Cookies may be used will be deleted when the browser is closed. You can prevent the storage of cookies by setting your browser software accordingly.',
                matomoText5: 'The data processing is based on your consent (§ 25 Para. 1 TTDSG, i.e. German Telecommunications Telemedia Data Protection Act).',
                matomoText6: 'You can refuse your consent or revoke it at any time. In this case, an opt-out cookie will be stored in your browser, which prevents Matomo from storing usage data. If you delete cookies, this has the effect that the Matomo opt-out cookie is also deleted. The opt-out would then have to be reactivated when you visit our site again.',
            },
            facebook: {
                facebook: 'Facebook',
                facebookText1: 'For our profile on the social network Facebook, we are jointly responsible with Meta Platforms Ireland Limited (hereinafter referred to as Facebook) for collecting (but not further processing) data from visitors to our Facebook page (so-called "fan page"). This data includes information about the types of content users view or interact with, or the actions they take (see in the Facebook Data Policy at ',
                facebookText2: ' under "Things that you and others do and provide"), as well as information about the devices users use (e.g., IP addresses, operating system, browser type, language settings, cookie data; see in the Facebook Data Policy, ',
                facebookText3: ', under "Device information"). As Facebook explains there (under "How do we use this information?"), Facebook also collects and uses information to provide analytics services to Page operators (so-called "Page Insights"). Page operators thus receive insights into how people interact with their pages and with the associated content. We have concluded an agreement with Facebook for this purpose ("Information about Page Insights" at ',
                facebookText4: '), which regulates in particular which security measures Facebook must observe and in which Facebook agrees to fulfil the data subject rights (i.e. users can, for example, send information or deletion requests directly to Facebook). The rights of users (in particular to information, deletion, objection and complaint to the competent supervisory authority) are not restricted by the agreements with Facebook. Further information can be found in the "Information about Page Insights" at ',
                facebookText5: 'The service provider is Meta Platforms Ireland Limited, 4 Grand Canal Square, Grand Canal Harbour, Dublin 2, Ireland. Data protection information from Facebook can be found at ',
                facebookText6: 'Data transfer to third countries is based on the EU standard contractual clauses, which you can view here: ',
                facebookText7: '. Further information on the shared responsibility agreement can be found here: ',
            },
            twitter: {
                twitter: 'Twitter',
                twitterText1: 'The service provider is Twitter International Company, One Cumberland Place, Fenian Street, Dublin 2 D02 AX07, Ireland. You can find Twitter\'s data protection information at ',
                twitterText2: '. You can submit your objection (opt-out) here: ',
            },
            instagram: {
                instagram: 'Instagram',
                instagramText1: 'The service provider is Instagram Inc, 1601 Willow Road, Menlo Park, CA, 94025, USA. You can find Instagram\'s data protection information at ',
            },
            linkedIn: {
                linkedIn: 'LinkedIn',
                linkedInText1: 'Service provider is LinkedIn Ireland Unlimited Company, Wilton Place, Dublin 2, Ireland. You can find data protection information from Linkedin at ',
                linkedInText2: '. We have concluded an order processing agreement with LinkedIn (',
                linkedInText3: '). Data transfer to third countries takes place on the basis of the EU Standard Contractual Clauses, which you can view here: ',
                linkedInText4: '. You can lodge your objection (opt-out) here: ',
            },
        },
        businessUnits: {
            pigmentsAndDyes: {
                pigments: 'Pigments & Dyes',
                buText: 'Imagine a world without colours. From a soft grey to a powerful red, from bright white to intensive black and every brilliant shade you can think of: Colour is everywhere. Within our Pigments & Dyes division we offer an extensive portfolio of different colourant groups: Black and white pigments, organic and inorganic colour pigments and dyes as well as all kinds of effect pigments and dyes. Find out how we can support your coloration challenges.',
            },
            performanceMaterialsAndFillers: {
                fillers: 'Performance Materials & Fillers',
                highQualityProductsRequirePerformance: 'High quality products require Performance!',
                areYouLookingForRawMaterialsWithTheseFunctionalitiesProperties: 'Either you look for',
                buText1: 'and many other features including a synergistic combination of multiple of them.',
                buText2: 'You\'ll find your topmost performing solutions in our portfolio of performance materials and functional fillers. High quality products also require sustainability! If you are interested in combining performance with effective circularity and efficient energy use, please reach out to us!',
            },
            binders: {
                binders: 'Resins',
                buText: 'Resins and hardeners are an integral part of formulations across various applications. Grolman offers a portfolio of specialty resins and hardeners that enable our customers to formulate products for different purposes - like the varnish on your garden fence, the wall paint in your living room, polymers for the caulk in your bathroom or curing agents for the epoxy adhesive in your brand new electrical vehicle. Contact us and find out which solutions we can offer you.',
            },
            additives: {
                additives: 'Additives',
                buText: 'Additives are the salt in the soup when it comes to coating formulations. Whether you need an adhesion promoter, accelerator, defoamer or any other additive to enhance the performance of your formulation – Grolman can offer a wide range of additives that help you solve your formulation challenges. Contact us and let us know how we can help you.',
            }
        },
        functionalities: {
            barrierProperties: 'Barrier properties',
            coloursAndEffects: 'Colours & effects',
            increasedVisibility: 'Increased visibility',
            irReflective: 'IR-reflective',
            antiCorrosion: 'Anti-corrosion',
            electricalAndThermalConductivity: 'Electrical & thermal conductivity',
            renewableRawMaterials: 'Renewable raw materials',
            defoaming: 'Defoaming',
            wettingAndDispersing: 'Wetting and Dispersing',
            levellingAndSlip: 'Levelling and Slip',
            viscosityModification: 'Viscosity modification',
            adhesion: 'Adhesion',
            lightProtection: 'Light protection',
            longerLifetime: 'Longer lifetime',
            thermalInsulation: 'Thermal Insulation',
            ageingStability: 'Ageing stability',
            mattingEffects: 'Matting effects',
            lightReflectance: 'Light reflectance',
            circularity: 'Circularity',
            flameRetardancy: 'Flame retardancy',
            tribology: 'Tribology',
            viscosityAndRheologyModification: 'Viscosity & rheology modification',
            flameRetardancy: 'Flame Retardancy',
            heatManagement: 'Heat Management',
            colourAndEffectPigmentation: 'Colour & effect pigmentation',
            durability: 'Durability',
            uvProtection: 'UV Protection',
            mechanicalImprovement: 'Mechanical Improvement',
            electricalProperties: 'Electrical Properties',
            airEntrainingAgent: 'Air Entraining Agent',
            shrinkageControl: 'Shrinkage control',
            viscosityModification: 'Viscosity modification',
            temperatureResistance: 'Temperature Resistance',
            enhancedElectricalProperties: 'Enhanced Electrical Properties',
            rheologyModification: 'Rheology modification',
            abrasionAndScratchResistance: 'Abrasion and scratch resistance',
            surfaceImprovement: 'Surface improvement',
            weightReduction: 'Weight reduction',
            laserMarking: 'Laser marking',
            thermalConductivity: 'Thermal conductivity',
            crosslinkingProperties: 'Crosslinking properties',
            coloursAndPigmentsForSpecialEffects: 'Colours & Pigments for special effects',
            functionalFillersAndExtenders: 'Functional fillers & Extenders',
            laminatingAdhesives: 'Laminating adhesives',
            opticalBrighteners: 'Optical brighteners',
            thickeners: 'Thickeners',
            defoamers: 'Defoamers',
            electricalConductivityAndInsulation: 'Electrical conductivity and insulation',
            mechanicalStrength: 'Mechanical strength',
            heatTransfer: 'Heat transfer',
            electricalInsulation: 'Electrical insulation',
            resistance: 'Resilience',
            dimensionalStability: 'Dimensional stability',
            rheologyImprovers: 'Rheology improvers',
            easyProcessability: 'Ease of processing',
            soundAbsorbing: 'Sound deadening',
        },
        industries: {
            paintsAndCoatings: {
                surfaceCoatings: 'Paints & Coatings',
                applicationsText: 'Decorative Coatings, Wood Coatings, Industrial Coatings, ACE Coatings, Coil- and Can Coatings, Powder Coatings, Automotive Coatings, Marine Coatings, Protective Coatings, Aerospace Coatings and Artists’ Colours, Heavy Duty Coatings, Anti Corrosion Coatings, Road Marking Paints, Specialty Coatings',
                industryText: 'The ability of coating engineers to respond quickly to the needs of their customers has become a key success factor in the industry. With our innovative and more sustainable solutions for paints and coatings formulations we support our customers in developing smarter solutions across diverse end-use industries. Grolman offers a portfolio of speciality raw materials from first class suppliers to serve the industry\'s needs. Our resins, additives, pigments and dyes, fillers and performance materials for paints and coatings offer the following functionalities:',
            },
            adhesives: {
                adhesives: 'Adhesives',
                applicationsText: 'Building & Construction, Transportation, Paper, board and related products, Footwear & leather, Woodworking & joinery, Consumer/DIY, Assembly operations, Adhesives tapes, Medical adhesives',
                industryText1: 'Adhesives are used to bond different substrates in various environments. This results in a huge number of formulations to meet the requirements of the specific application. The adhesives industry is always striving to improve technologies in order to offer their customers the best solution and meeting the highest quality standards. To achieve this, it is crucial to have a deep understanding of how different raw materials interact, affect the formulation and the performance of an adhesive. Here you can trust our technical expertise in the formulation of tailor-made solutions for a diverse range of applications.',
                industryText2: 'Our resins, additives, pigments and dyes, fillers and performance materials for adhesives offer the following functionalities:',
            },
            printingInksAndGraphicArts: {
                printingInk: 'Printing Inks & Graphic Arts',
                marketSegmentsText: 'Digital inks, flexographic inks, gravure inks, lithographic inks, offset inks, screen inks, security inks, UV inks, writing products.',
                industryText: 'Printing inks have different requirements than paints and varnish coatings as they are applied in the form of a very thin layer. The visual characteristics of the ink depend on three main factors: its colour, transparency and gloss. Our team will be happy to support you in finding the optimal raw materials and the best composition for your ink formulations. Our resins, additives, pigments and dyes, fillers and performance materials for printing inks and graphic arts offer the following functionalities:',
            },
            rubber: {
                rubber: 'Rubber',
                applicationText: 'Rubber compounds used for extrusion, calandering, coating, moulding and casting',
                industryText1: 'By using tailored specialty chemicals, the raw material rubber is turned into a high-performance product. With the selection of the respective rubber type as well as the crosslinking chemicals and additives, the final product obtains the desired technical properties - whether you want to improve the endurance, fuel efficiency properties, performance, etc.',
                industryText2: 'Grolman offers individual and innovative product solutions for the rubber industry. Our resins, additives, pigments and dyes, fillers and performance materials for rubber offer the following functionalities:',
            },
            construction: {
                construction: 'Construction',
                applicationText: 'Electrical Properties, Specialized concrete admixtures, Concrete prefabrication & Ready mix concrete, Dry and wet cement mortars, Specialised paints and sealing coats, Road asphalt modifiers, Innovative nano-additive',
                industryText1: 'We support you in developing applications in the construction market such as architectural and construction concretes, concrete prefabricated paving stones, large-size concrete prefabricates, dry and wet cement mortars, floors, insulation, roofs, walls, facades, composites and more. With the help of our raw materials buildings can be more durable and require fewer resources for maintenance. Our specialty chemicals also make buildings more energy efficient, thus protecting our environment. Grolman offers a portfolio of speciality raw materials for different construction requirements.',
                industryText2: 'Our additives, pigments and dyes, fillers and performance materials for construction offer the following functionalities:',
            },
            plastics: {
                plastics: 'Plastics',
                applicationText: 'Thermoplastic Compound / Extrusion, Thermoplastic Masterbatch, Thermoplastic Composites, Thermoset, Thermoset Composites, Elastomers',
                industryText1: 'Avoiding plastic waste, using environment-friendly materials, making efficient use of resources and energy and improving the quality of life: In a time of continuous change the plastics industry is constantly challenged to come up with the next innovation. As consumers and communities demand more and more, new sustainable high-performance materials will be key.',
                industryText2: 'At Grolman, we support you with material expertise and extraordinary market insight to design, develop and produce the specialty plastics that fulfil your customers\' needs. Our resins, additives, pigments and dyes, fillers and performance materials for plastics offer the following functionalities:',
            },
            paper: {
                paper: 'Paper',
                applicationText: 'Coated Graphic Paper, Specialty paper, Decor Paper (pre-impregnated), Paper for Technical Laminates, Banknote & Security Paper, Carbon Paper, Friction Paper, Technical paper for the industry',
                industryText1: 'We support producers of paper in meeting today\'s requirements.',
                industryText2: 'Our innovative solutions help with increasing productivity and sustainability while reducing total cost of operation and ensuring quality demands.',
                industryText3: 'Our resins & additives, pigments and dyes, fillers and performance materials address the needs of the pulp and paper industry.',
                productsAndProperties: 'Products & Properties',
            },
            sealants: {
                sealants: 'Sealants',
                applicationText: 'Construction, Transportation, Consumer/DIY, Assembly operations',
                industryText: 'Sealants are soft, elastic materials that seal cracks or joints to prevent penetration of liquids, gases, substances or airborne particles. This purpose requires some adhesive and cohesive properties but also long-term functionality. These are only three topics of large number of very different requirements which call for special solutions to meet the individual needs.  Grolman’s technical experts are eager to help you to identify the best raw materials for your specific application. Our resins, additives, pigments and dyes, fillers and performance materials for sealants offer the following functionalities:',
            }
        },
    },
    // German
    de:
    {
        languageNo: 4,
        languageName: 'Deutsch',
        flag:'de',
        theGrolmanGroup: 'Die Grolman Gruppe',
        internationalDistributionOfSpecialtyChemicals:'International Distribution of Specialty Chemicals',
        personalCare: 'Personal Care',
        industry: 'Industrie',
        imprint: 'Impressum',
        privacy: 'Datenschutz',
        msdsDownload: 'MSDS Download',
        msds:'Sicherheitsdatenblätter',
        contact: 'Kontakt',
        downloads: 'Downloads',
        resources: 'Resources',
        msdsDownloadSentence: 'Unsere Sicherheitsdatenblätter (SDB) können Sie unter dieser URL herunterladen:',
        certificate: 'Zertifikat',
        emailCertificates: 'E-Mail Zertifikate',
        emailCertificatesSentence: 'Zur Überprüfung der Gültigkeit von persönlichen Grolman E-Mail-Zertifikaten stellen wir Ihnen hier das Zertifikat der Grolman Stammzertifizierungsstelle ("CA") zum Download bereit.',
        fingerprint: 'Fingerprint',
        termsAndConditions: 'Liefer- & Zahlungsbedingungen',
        termsAndConditionsFooter: '<span class="whiteSpaceNoWrap">Liefer- &</span> Zahlungsbedingungen',
        homeStartText: 'Die Grolman Gruppe vertreibt Produkte der Spezialchemie renommierter internationaler Produzenten. Ein weit gespanntes Netz an Lägern gewährleistet eine effiziente und kundennahe Distribution von Additiven, Füllstoffen, Farb- und Bindemitteln. Hochqualifizierte Mitarbeiter im Innen- und Außendienst betreuen Kunden in der Farben- und Lack-, Klebstoff-, Dichtungsmassen-, Druckfarben-, Kautschuk-, Kunststoff- Pharma-, Kosmetik- und Papier-Industrie.',
        companyText1:'Die Grolman Gruppe steht für den internationalen Vertrieb von Produkten der Spezialchemie. Das Unternehmen unterhält einzelne Verkaufsbüros in allen europäischen Ländern, der Türkei, Maghreb, Ägypten, Indien und China die jeweils durch technischen Vertrieb, Kundenserviceteams und Lagerhaltung unterstützt werden. ',
        companyText2:'Das in der fünften Generation geführte Familienunternehmen befindet sich seit seiner Gründung im Jahre 1855 in Privatbesitz. Der Schlüssel zum Unternehmenserfolg liegt in einer kundenorientierten und effizient organisierten Unternehmensstruktur, bei der die Bedürfnisse der Kunden die treibende Kraft sind.',   
        grolmanQualitySince1855:'Grolman. Qualität seit 1855.',
        partner: 'Partner',
        meetUs: 'Meet us',
        exhibitions: 'Meet us',
        meetUsHeaderText: 'Meet us',
        locations: 'Standorte',
        selectLocation: 'Wähle Standort',
        selectLanguage: 'Wähle Sprache',
        selectCategory: 'Wähle Kategorie',
        pleaseSelectACategory: 'Bitte wählen Sie eine Kategorie',
        productsText: 'Spezialitäten für die Farben-, Lack-, Klebstoff-, Bauchemische-, Dichtungsmassen-, Druckfarben-, Kautschuk-, Kunststoff-, Pharma-, Kosmetik- und Papierindustrie.',
        industriesText: 'Bindemittel, Additive, Pigmente und Füllstoffe aus einer Hand.',
        vatRegistrationNumber: 'Umsatzsteuernummer',
        registrationOffice: 'Handelsregister',
        taxRegistrationNumber: 'Tax Registration Number',
        surfaceCoatings: 'Farben & Lacke',
        adhesivesAndSealants: 'Kleb- & Dichtstoffe',
        adhesives: 'Klebstoffe',
        printingInk: 'Druckfarben & Graphic Arts',
        paper: 'Papier',
        agriculture: 'Agriculture',
        construction: 'Bauchemie',
        plastics: 'Kunststoffe',
        rubber: 'Kautschuk / Gummi',
        sealants: 'Dichtstoffe',
        pharma: 'Pharma',
        miscellaneous: 'Verschiedenes',
        moreIndustries: 'Weitere Industrien',
        battery: 'Batterie',
        pleaseSelectACountryForMoreInformation: 'Bitte wählen Sie ein Land aus, um weitere Informationen zu erhalten.',
        producer: 'Hersteller',
        tradeName: 'Handelsname',
        description: 'Beschreibung',
        applications: 'Anwendungen',
        marketSegments: 'Marktsegmente',
        forMoreDetailsReferToOurCountryPages:'Weitere Details finden Sie auf den jeweiligen Landesseiten.',
        noFilesAvailable:'Keine Dateien verfügbar',
        managingDirectors:'Geschäftsführer',
        generalManager:'General Manager',
        editor:'Verantwortlicher Redakteur',
        theDataProtectionOfficerIsProvidedBy: 'Die Datenschutzbeauftragte wird gestellt von',
        legalInformation:'Rechtlicher Hinweis',
        meetUsOnOurStand:'Besuchen Sie uns auf unserem Stand',
        meetUsAt:'Besuchen Sie uns auf dem',
        meetUsIn:'Besuchen Sie uns in',
        stand:'Stand',
        hall: 'Halle',
        meetUsDigitally: 'Treffen Sie uns digital',
        externalLink:'Externer Link',
        managingDirectorFemale: 'Geschäftsführerin',
        january: 'Januar',
        february: 'Februar',
        march: 'März',
        april: 'April',
        may: 'Mai',
        june: 'Juni',
        july: 'Juli',
        august: 'August',
        september: 'September',
        october: 'Oktober',
        november: 'November',
        december: 'Dezember',
		postponed: 'NEUER TERMIN',
        moreInfo: 'Weitere Informationen',
        noFurtherInformationAvailable: 'Für weitere Informationen zu unseren Additiven, Bindemitteln, Pigmenten, Farbstoffen, Füllstoffen und Performance Materials wenden Sie sich bitte an unsere lokalen Ansprechpartner unter ',
        worthWhileChat: 'Kontaktieren Sie uns, um einen unserer Worth.While.Chats zu besuchen - ein neues interaktives online Format mit spannenden Themen.',
        onlineSeminars: 'Nehmen Sie an Online Seminaren mit unseren Lieferanten teil!',
        stockAppointmentBooking: 'Lager Terminbuchung',
        stockAppointmentBookingSentence: 'Sie möchten Ware aus unserem Lager in Neuss abholen oder Ware anliefern? Dann buchen Sie bequem einen Termin in unserem Lager über den folgenden Link:',
        integrityNextProfile: 'Integrity Next Profil',
        integrityNextProfileSentencePart1: 'Schauen Sie sich unser',
        integrityNextProfileSentencePart2: 'Nachhaltigkeitsprofil auf Integrity Next',
        integrityNextProfileSentencePart3: 'an, um einen schnellen Überblick über unsere Nachhaltigkeitsstandards zu erhalten.',
        rspoMembership: 'RSPO-Mitgliedschaft',
        rspoMembershipSentence: 'Grolman International Distribution ist ordentliches Mitglied des RSPO (Roundtable on Sustainable Palm Oil) und hält eine entsprechende Vertriebslizenz. RSPO ist eine internationale Non-Profit-Organisation, die 2004 mit dem Ziel gegründet wurde, das Wachstum und die Verwendung nachhaltiger Ölpalmprodukte durch glaubwürdige globale Standards und die Einbindung von Interessengruppen zu fördern. Grolman International Distribution bestätigt die Umsetzung der RSPO-Anforderungen für die Palmöl-enthaltenden Produkte und zeigt die Einhaltung durch seine jährliche Fortschrittskommunikation (ACOP) und das MyRSPO-Mitgliederprofil. Darüber hinaus verfügt Grolman International Distribution über ein nachhaltiges Managementsystem, das die Anforderungen der RSPO abdeckt.',
        rspoDistributorLicense: 'RSPO Distributor-Lizenz',
		womensAndEqualOpportunitiesOfficer: 'Frauen- und Gleichstellungsbeauftragte',
        noProductsToDisplay: "Wir haben derzeit keine Produkte in dieser Kategorie zu zeigen",
        codeOfConduct: "Code of Conduct",
        sustainabilityReport: "Nachhaltigkeitsbericht",
        sustainabilityReporting: "Nachhaltigkeitsberichte",
        policyDocuments: "Richtlinien Dokumente",
        policyDocumentsSentence: "Hier finden Sie die Dokumente zu unseren Richtlinien",
        supplierAssessment: "Lieferantenbewertung",
        conflictMineralsPolicy: "Richtlinie zu Konfliktmineralien",
        whistleblowerPolicy: "Whistleblower-Richtlinie",
        shePolicy: "SHE-Richtlinie",
        environmentalPolicyAndSustainabilityStandards: "Umweltpolitik und Nachhaltigkeitsstandards",
        positionOnHumanRightsDiversityAndInclusion: "Position zu Menschenrechten, Diversität und Inklusion",
        antiCorruptionAndAntiBriberyPolicy: "Antikorruptions- und Anti-Bestechungspolitik",
        sustainabilityReportSentence: "Sehen Sie sich unsere Nachhaltigkeitsberichte an, die unsere laufenden Bemühungen aufzeigen, eine positive Wirkung zu erzielen. Entdecken Sie, wie wir Nachhaltigkeit in allen unseren Geschäftsbereichen umsetzen und zu einer grüneren, integrativeren Zukunft für alle beitragen.",
        complianceForm: "Compliance Form",
        codeOfConductSentence: "Wenn Sie mehr über unsere Unternehmensvision und unser unternehmerisches Handeln erfahren möchten, laden Sie bitte unseren Verhaltenskodex und unsere Geschäftsethik herunter.",
        sustainabilityStatement: "Sustainability Statement",
        sustainabilityStatementSentence: "Hier finden Sie unsere Nachhaltigkeitserklärung, die unser Engagement für Umwelt-, Sozial- und Corporate-Governance-Prinzipien (ESG) sowie wichtige Informationen und Dokumente, die unser Ziel veranschaulichen.",
        whistleblower: 'Hinweisgeber-System',
        introduction: 'Einleitung',
        whistleblowerIntroductionSentence: 'Die Gustav Grolman GmbH & Co. KG und alle ihre internationalen Vertriebstöchter, im Folgenden als "Grolman" oder "Grolman International Distribution" bezeichnet, verpflichten sich, ihre Geschäfte in einer ethischen und ehrlichen Art und Weise zu führen und ein Whistleblower-System zu implementieren und durchzusetzen, um sicherzustellen, dass alle ihre Geschäftsaktivitäten in einer solchen Art und Weise durchgeführt werden. ',
        purpose: 'Zweck',
        whistleblowerPurposeSentence: 'Diese Richtlinie bietet einen Rahmen für den Schutz von Hinweisgebern, die Meldung von Dritten in einer Software, die die Anonymität wahrt, den Untersuchungsprozess sowie die verschiedenen Rollen und Verantwortlichkeiten innerhalb dieses Prozesses. Diese Richtlinie enthält Informationen über die Software, über die mutmaßliche rechtswidrige Handlungen gemeldet werden können.',
        actionsAndResponsibilities: 'Handlungen und Verantwortlichkeiten',
        whistleblowerActionsAndResponsibilitiesSentence: 'Zu diesem Zweck hat die Geschäftsleitung von Grolman International Distribution die Maßnahmen ergriffen und die Verantwortung übernommen, in ein weltweit anerkanntes Whistleblowing-System zu investieren, das von einer dritten Partei betrieben wird: Grant Thornton; ',
        distributionIn: 'Vertrieb in',
        search: 'Suche',
        links: 'Links',
        productGroups: 'Produktgruppen',
        contacts: 'Kontakte',
        menu: {
            home: 'Home',
            company: 'Unternehmen',
            products: 'Produkte',
            industries: 'Industrien',
            facts: 'Fakten',
            lab: 'Labor',
            jobs: 'Jobs',
            visionAndSustainability: 'Vision & Nachhaltigkeit',
            history:'Geschichte',
        },
        jobsSection: {
            headerText: 'Hier finden Sie unsere offenen Stellenausschreibungen.',
            noJobsAvailablePart1: 'Hallo zusammen! Leider ist diese Seite im Moment leer, da wir im Moment keine offenen Stellen haben. Aber kontaktieren Sie uns doch einfach unter',
            noJobsAvailablePart2: 'und lassen Sie uns wissen, warum Sie unser Team verstärken möchten! Wir freuen uns darauf, Sie kennen zu lernen!',
            footerText: 'Interessieren Sie sich für diese Stelle, senden Sie bitte Ihre Bewerbungsunterlagen an ',
            findDetailsInPDF: 'Weitere Details zu dieser Stelle können Sie diesem PDF Dokument entnehmen',
            fullTime: 'Vollzeit',
            mf: '(m/w)',
            mfd: '(m/w/d)',
        },
        optOut: {
            dataPrivacyInformation: 'Datenschutzhinweis',
            tracking: 'Tracking',
            weUseCookiesNote: 'Wir verwenden Cookies, um Ihnen die optimale Nutzung unserer Webseite zu ermöglichen. Es werden für den Betrieb der Seite notwendige Cookies gesetzt. Darüber hinaus können Sie Cookies für Statistikzwecke zulassen. Sie können die Datenschutzeinstellungen anpassen oder allen Cookies direkt zustimmen.',
            changePrivacySettings: 'Datenschutzeinstellungen anpassen',
            acceptAllCookies: 'Ja, ich bin mit allen Cookies einverstanden',
            matomoCookieAndTrackingExplanation: 'Wir nutzen auf unserer Internetseite das Open-Source-Software-Tool Matomo. Mit Matomo werden keine Daten an Server übermittelt, die außerhalb der Kontrolle von Gustav Grolman GmbH & Co. KG liegen. Das Tool verwendet Cookies. Mit diesen Cookies können wir Besuche zählen. Diese Textdateien werden auf Ihrem Computer gespeichert und machen es Gustav Grolman GmbH & Co. KG möglich, die Nutzung seiner Webseite zu analysieren. Ihre IP-Adresse ist für uns eine anonyme Kennung; wir haben keine technische Möglichkeit, Sie damit als angemeldeten Nutzer zu identifizieren. Sie bleiben als Nutzer anonym. Wenn Sie mit der Auswertung Ihrer Daten einverstanden sind, dann aktivieren Sie bitte diesen Cookie.',
            acceptChanges: 'Änderungen speichern',
            allowAll: 'Alle zulassen',
            linkToPrivacyStatement: 'Zur Datenschutzerklärung',
            allowTracking: 'Tracking erlauben',
        },
        privacyTexts: {
            privacyStatement: 'Datenschutz-Informationen',
            whenYouVisitOurWebsite: 'Bei Ihrem Besuch unserer Webseiten verarbeiten wir personenbezogene Daten. Darüber und über Ihre Rechte möchten wir Sie hier informieren.',
            dataProcessingWhenUsingThisSite: 'Datenverarbeitungen bei Nutzung dieser Webseiten',
            weProcessData: 'Wir verarbeiten Ihre personenbezogenen Daten folgendermaßen:',
            serverLogFiles: 'Server Log Daten',
            automaticallyProcessesInformationProvidedByBrowser: 'Der Provider der Seiten erhebt und speichert automatisch Informationen in so genannten Server-Log-Dateien, die Ihr Browser automatisch übermittelt. Dies sind vor allem:',
            browserTypeAndVersion: 'Browsertyp and Browserversion',
            operatingSystem: 'verwendetes Betriebssystem',
            referrerUrl: 'Referrer URL (Herkunftsadresse)',
            dateAndTimeOfServerRequest: 'Datum und Uhrzeit der Serveranfrage',
            ipAddress: 'IP Addresse',
            serverLogFilesText1: 'Diese Daten werden getrennt von anderen Daten, die Sie unter Umständen an uns übermitteln, gespeichert und nicht mit Daten aus anderen Quellen zusammengeführt.',
            serverLogFilesText2: 'Grundlage für die Datenverarbeitung ist Art. 6 Abs. 1 Buchst. f DSGVO, der die Verarbeitung von Daten auf Grund berechtigter Interessen gestattet. In diesem Fall besteht ein berechtigtes Interesse an einem sicheren und störungsfreien Betrieb des Webservers. Um diesen sicherzustellen, muss die Administration über Serverlogfiles Angriffe und Fehlfunktionen des Systems erkennen und nachvollziehen können. Um Angriffsmuster erkennen zu können, müssen Zugriffe auf den Server gespeichert werden. Sobald diese Daten nicht mehr benötigt werden, werden sie gelöscht. Die Daten stehen aus technischen Gründen dem Hosting-Dienstleister zur Verfügung, der uns gegenüber jedoch weisungsgebunden und vertraglich verpflichtet ist.',
            hereYouCanGiveOrRevokeCookieConsent: 'Hier können Sie Ihre Einwilligung erteilen oder widerrufen:',
            giveOrRevokeConsent: 'Einwilligung erteilen oder widerrufen',
            furtherDataProcessingInInternetPresencesOnSocialNetworks: 'Weitere Datenverarbeitungen in Internetauftritten sozialer Netzwerke',
            socialNetworksText1: 'Wir sind in sozialen Netzwerken präsent und betreiben dort Auftritte. Um mit den dort aktiven Nutzern zu kommunizieren und um Informationen über uns anzubieten, verarbeiten wir Daten über die Nutzer dieser Netzwerke.',
            socialNetworksText2: 'Wir weisen darauf hin, dass dabei Daten der Nutzer außerhalb der Europäischen Union und des Europäischen Wirtschaftsraumes verarbeitet werden können. Dadurch können Risiken für die Nutzer entstehen, weil sie z.B. ihre Rechte schwerer durchsetzen können.',
            socialNetworksText3: 'In der Regel werden in den sozialen Netzwerken Nutzerdaten auch für Zwecke der Marktforschung und Werbung verarbeitet. Anhand des Nutzungsverhaltens können beispielsweise Nutzungsprofile erstellt werden. Diese können herangezogen werden können, um innerhalb und außerhalb der Netzwerke Werbeanzeigen zu schalten, die den mutmaßlichen Interessen der Nutzer entsprechen.',
            socialNetworksText4: 'Das Nutzungsprofil kann in Cookies gespeichert werden, die im Endgerät der Nutzer abgelegt werden. Eine Speicherung kann auch direkt im sozialen Netzwerk erfolgen, insbesondere wenn die Nutzer Mitglied der jeweiligen Plattformen und dort eingeloggt sind.',
            socialNetworksText5: 'Datenarten, die verarbeitet werden, sind insbesondere Kontaktdaten (z.B. E-Mail, Telefonnummer), Inhaltsdaten (z.B. Angaben in Formularen), Nutzungsdaten (z.B. besuchte Webseiten, Interesse an Inhalten, Zugriffszeiten), Meta- und Kommunikationsdaten (z.B. Geräte-Informationen, IP-Adressen).',
            socialNetworksText6: 'Die davon betroffenen Personen sind die Nutzer der Webseiten und Netzwerke.',
            socialNetworksText7: 'Zwecke der Datenverarbeitung sind die Bearbeitung von Kontaktanfragen und Kommunikation, das Sammeln von Feedback und Marketing.',
            socialNetworksText8: 'Rechtsgrundlage für die Datenverarbeitung sind unsere berechtigten Interessen an Kommunikation und Marketing (Art. 6 Abs. 1 Buchst. f. DSGVO).',
            socialNetworksText9: 'Für eine detaillierte Darstellung der jeweiligen Datenverarbeitungen und der Möglichkeiten zum Widerspruch (Opt-Out) verweisen wir auf die Datenschutz-Informationen der Betreiber der jeweiligen Netzwerke. Auskunftsanfragen und die Geltendmachung von Nutzerrechten können am effektivsten beim jeweiligen Betreiber geltend gemacht werden; auf Anfrage können wir dabei unterstützen.',
            furtherInformationAboutSocialNetworks: 'Weitere Hinweise über soziale Netzwerke:',
            yourRights: 'Ihre Rechte',
            yourRightsText1: 'Als Betroffener stehen Ihnen die folgenden Rechte zu, soweit die gesetzlichen Voraussetzungen dafür erfüllt sind:',
            right1: 'Recht auf Auskunft, Art. 15 DSGVO',
            right2: 'Recht auf Berichtigung, Art. 16 DSGVO',
            right3: 'Recht auf Löschung, Art. 17 DSGVO',
            right4: 'Recht auf Einschränkung der Verarbeitung, Art. 18 DSGVO',
            right5: 'Recht auf Datenübertragbarkeit, Art. 20 DSGVO',
            right6: 'Widerspruchsrecht, Art. 21 DSGVO',
            yourRightsText2: 'Sie besitzen das Recht, Ihre erteilte Einwilligung zur Verarbeitung der Daten jederzeit mit Wirkung für die Zukunft zu widerrufen.', 
            yourRightsText3: 'Zudem haben zudem Sie das Recht, sich bei der Datenschutz-Aufsichtsbehörde über die Datenverarbeitung zu beschweren.',
            responsibilityAndDataProtectionOfficer: 'Verantwortliche Stelle und Datenschutzbeauftragte',
            controllerOfProcessingPersonalData: 'Die verantwortliche Stelle für die Datenverarbeitung ist:',
            dataProtectionOfficerFoxOn: 'Die externe Datenschutzbeauftragte erreichen Sie über fox-on Datenschutz GmbH, Pollerhofstr. 33a, 51789 Lindlar, Germany, email: dsb@fox-on.com.',
            dataProtectionOfficerThomasSchlueter: 'Den externen Datenschutzbeauftragten erreichen Sie über Dr. Thomas Schlüter, Warendorfer Strasse 60, 48145 Münster, Germany, email: datasecurity@grolman-group.com.',
            cookies: {
                cookies: 'Cookies',
                cookiesText1: 'Die Internetseiten verwenden Cookies. Das sind Datensätze, die auf Ihrem Rechner abgelegt und ausgewertet werden können. Sie sind notwendig, um Ihnen die Webseiten bereitstellen zu können.',
                cookiesText2: 'Nähere Angaben zu den eingesetzten Cookies finden Sie den jeweiligen Abschnitten dieser Erklärung.',
                cookiesText3: 'Darüber hinaus kommen folgende Cookies zum Einsatz:',
                cookieConsent1: 'Einwilligung in Tracking: Ob Sie dem Einsatz von Tracking zugestimmt haben oder nicht, wird in einem Session-Cookie gespeichert.',
                cookieConsent2: 'Session-Cookies: Um Ihre Aktionen während einer Sitzung zuordnen zu können, können weitere Cookies angelegt werden. Mit dem Schließen des Browsers werden sie automatisch gelöscht. Sie dienen nicht der Auswertung Ihres Nutzerverhaltens.',  
                cookiesText4: 'Diese Cookies sind technisch erforderlich und werden auf Grundlage von § 25 Abs. 2 Nr. 2 TTDSG verarbeitet. Wir besitzen ein berechtigtes Interesse an der technisch fehlerfreien und optimierten Bereitstellung unserer Dienste.',
                cookiesText5: 'Sie können Ihren Browser so einstellen, dass Sie über das Setzen von Cookies informiert werden und Cookies nur im Einzelfall erlauben, die Annahme von Cookies für bestimmte Fälle oder generell ausschließen sowie das automatische Löschen der Cookies beim Schließen des Browsers aktivieren. Bei der Deaktivierung von Cookies kann die Funktionalität dieser Internetseiten eingeschränkt sein.',
            },
            matomo: {
                matomo: 'Matomo',
                matomoText1: 'Diese Website nutzt den Webanalysedienst Matomo, um die Nutzung der Webseiten seitenübergreifend analysieren zu können. Über die gewonnenen Statistiken können wir das Angebot verbessern und für Sie als Nutzer interessanter ausgestalten.',
                matomoText2: 'Die Informationen, die Matomo über Ihre Nutzung dieser Website erfasst, werden auf unserem Server gespeichert (etwa aufgerufene Seiten, Datum und Uhrzeit der Anfrage, Referrer-URL (zuvor besuchte Seite), Browser-Typ und Version, verwendetes Betriebssystem). Ihre IP-Adresse wird vor der Speicherung anonymisiert.',
                matomoText3: 'Die erzeugten Informationen über die Benutzung dieser Website werden nicht an Dritte weitergegeben.',
                matomoText4: 'Eventuell verwendete Cookies werden beim Schließen des Browsers wieder gelöscht. Sie können die Speicherung der Cookies durch eine entsprechende Einstellung Ihrer Browser-Software verhindern.',
                matomoText5: 'Die Datenverarbeitung erfolgt auf Grundlage Ihrer Einwilligung (§ 25 Abs. 1 TTDSG).',
                matomoText6: 'Falls Sie Ihre Einwilligung nicht erteilen oder widerrufen, wird in Ihrem Browser ein Opt-Out-Cookie hinterlegt, das verhindert, dass Matomo Nutzungsdaten speichert. Wenn Sie Cookies löschen, hat dies zur Folge, dass auch das Matomo-Opt-Out-Cookie gelöscht wird. Das Opt-Out müsste dann bei einem erneuten Besuch unserer Seite erneut aktiviert werden.',
            },
            facebook: {
                facebook: 'Facebook',
                facebookText1: 'Für unser Profil im sozialen Netzwerk Facebook sind wir gemeinsam mit der Meta Platforms Ireland Limited verantwortlich für die Erhebung (jedoch nicht für die weitere Verarbeitung) von Daten der Besucher unserer Facebook-Seite (sog. "Fanpage"). Unter diese Daten fallen Informationen über die Arten von Inhalten, welche sich Nutzer ansehen oder mit denen sie interagieren, oder über die von ihnen vorgenommenen Handlungen (siehe unter "Von dir und anderen getätigte und bereitgestellte Dinge" in der Facebook-Datenrichtlinie unter ',
                facebookText2: ' sowie Informationen über die von Nutzern genutzten Geräte (z. B. IP-Adressen, Betriebssystem, Browsertyp, Spracheinstellungen, Cookie-Daten; vgl. in der Facebook-Datenrichtlinie, ',
                facebookText3: ', unter "Geräteinformationen"). Wie Facebook dort erläutert (unter "Wie verwenden wir diese Informationen?"), erhebt und verwendet Facebook auch Informationen, um Analysedienste für Seitenbetreiber bereitzustellen (so genannte "Seiten-Insights"). Seitenbetreiber erhalten damit Erkenntnisse, wie Personen mit ihren Seiten und mit den damit verbundenen Inhalten interagieren. Wir haben mit Facebook dazu eine Vereinbarung abgeschlossen ("Informationen zu Seiten-Insights" unter ',
                facebookText4: '), in der insbesondere geregelt ist, welche Sicherheitsmaßnahmen Facebook beachten muss und in der Facebook sich bereit erklärt, die Betroffenenrechte zu erfüllen (d. h. Nutzer können etwa Auskünfte oder Löschungsanfragen direkt an Facebook richten). Die Rechte der Nutzer (insbesondere auf Auskunft, Löschung, Widerspruch und Beschwerde bei zuständiger Aufsichtsbehörde), werden durch die Vereinbarungen mit Facebook nicht eingeschränkt. Weitere Hinweise finden Sie in den "Informationen zu Seiten-Insights" unter ',
                facebookText5: 'Dienstanbieter ist Meta Platforms Ireland Limited, 4 Grand Canal Square, Grand Canal Harbour, Dublin 2, Irland. Datenschutz-Informationen von Facebook finden Sie unter ',
                facebookText6: 'Die Datenübermittlung in Drittländer erfolgt auf Grundlage der EU-Standardvertragsklauseln, die Sie hier einsehen können: ',
                facebookText7: '. Weitere Informationen zur Vereinbarung der gemeinsamen Verantwortlichkeit finden Sie hier: ',
            },
            twitter: {
                twitter: 'Twitter',
                twitterText1: 'Dienstanbieter ist die Twitter International Company, One Cumberland Place, Fenian Street, Dublin 2 D02 AX07, Irland. Datenschutz-Informationen von Twitter finden Sie unter ',
                twitterText2: '. Ihren Widerspruch (Opt-Out) können Sie hier einlegen: ',
            },
            instagram: {
                instagram: 'Instagram',
                instagramText1: 'Dienstanbieter ist die Instagram Inc., 1601 Willow Road, Menlo Park, CA, 94025, USA. Datenschutz-Informationen von Instagram finden Sie unter ',
            },
            linkedIn: {
                linkedIn: 'LinkedIn',
                linkedInText1: 'Dienstanbieter ist die LinkedIn Ireland Unlimited Company, Wilton Place, Dublin 2, Irland. Datenschutz-Informationen von Linkedin finden Sie unter ',
                linkedInText2: '. Wir haben einen Vertrag zur Auftragsverarbeitungsvertrag mit LinkedIn geschlossen (',
                linkedInText3: '). Die Datenübermittlung in Drittländer erfolgt auf Grundlage der EU-Standardvertragsklauseln, die Sie hier einsehen können: ',
                linkedInText4: '. Ihren Widerspruch (Opt-Out) können Sie hier einlegen: ',
            },
        },
        businessUnits: {
            pigmentsAndDyes: {
                pigments: 'Pigmente & Farbstoffe',
                buText: 'Stellen Sie sich eine Welt ohne Farben vor. Von sanftem Grau bis zu kräftigem Rot, von strahlendem Weiß bis zu intensivem Schwarz und allen nur denkbaren brillanten Farbtönen: Farbe ist überall. In unserer Abteilung Pigmente & Farbstoffe bieten wir ein umfangreiches Portfolio verschiedener Farbstoffgruppen an: Schwarz- und Weißpigmente, organische und anorganische Farbpigmente und Farbstoffe sowie alle Arten von Effektpigmenten und Farbstoffen. Finden Sie heraus, wie wir Sie bei Ihren Herausforderungen in der Farbgebung unterstützen können.',
            },
            performanceMaterialsAndFillers: {
                fillers: 'Performance Materials & Füllstoffe',
                highQualityProductsRequirePerformance: 'Hochwertige Produkte erfordern Leistung!',
                areYouLookingForRawMaterialsWithTheseFunctionalitiesProperties: 'Suchen Sie nach Rohstoffen mit diesen Funktionalitäten/Eigenschaften?',
                buText1: 'Unser Produktportfolio bietet Ihnen diese und viele andere Eigenschaften, einschließlich einer synergistischen Kombination mehrerer dieser Eigenschaften.',
                buText2: 'In unserem Portfolio an Performance Materials und funktionellen Füllstoffen finden Sie die leistungsfähigsten Lösungen. Hochwertige Produkte erfordern auch Nachhaltigkeit! Wenn Sie daran interessiert sind, Leistung mit effektiver Kreislaufwirtschaft und effizienter Energienutzung zu kombinieren, sprechen Sie uns an!',
            },
            binders: {
                binders: 'Bindemittel',
                buText: 'Bindemittel und Härter sind ein wesentlicher Bestandteil von Formulierungen für verschiedene Anwendungen. Grolman bietet ein Portfolio von speziellen Bindemitteln und Härtern, die es unseren Kunden ermöglichen, Produkte für unterschiedliche Zwecke zu formulieren - wie den Lack an Ihrem Gartenzaun, die Wandfarbe in Ihrem Wohnzimmer, Polymere für die Dichtungsmasse in Ihrem Badezimmer oder Aushärtemittel für den Epoxidkleber in Ihrem brandneuen Elektrofahrzeug. Kontaktieren Sie uns und finden Sie heraus, welche Lösungen wir Ihnen anbieten können.',
            },
            additives: {
                additives: 'Additive',
                buText: 'Additive sind das Salz in der Suppe, wenn es um Beschichtungsformulierungen geht. Ob Sie nun einen Haftvermittler, Beschleuniger, Entschäumer oder ein anderes Additiv benötigen, um die Leistung Ihrer Formulierung zu verbessern - Grolman kann Ihnen eine breite Palette von Additiven anbieten, die Ihnen helfen, Ihre Formulierungsprobleme zu lösen. Kontaktieren Sie uns und lassen Sie uns wissen, wie wir Ihnen helfen können.',
            }
        },
        functionalities: {
            barrierProperties: 'Barriereeigenschaften',
            coloursAndEffects: 'Farben und Effekte',
            increasedVisibility: 'Erhöhte Sichtbarkeit',
            irReflective: 'IR-reflektierend',
            antiCorrosion: 'Korrosionsschutz',
            electricalAndThermalConductivity: 'Elektrische und thermische Leitfähigkeit',
            renewableRawMaterials: 'Nachwachsende Rohstoffe',
            defoaming: 'Entschäumend',
            wettingAndDispersing: 'Benetzung und Dispergierung',
            levellingAndSlip: 'Nivellierung und Gleitfähigkeit',
            viscosityModification: 'Änderung der Viskosität',
            adhesion: 'Haftung',
            lightProtection: 'Lichtschutz',
            longerLifetime: 'Längere Lebensdauer',
            thermalInsulation: 'Thermische Isolierung',
            ageingStability: 'Alterungsbeständigkeit',
            mattingEffects: 'Mattierende Wirkung',
            lightReflectance: 'Lichtreflexion',
            circularity: 'Zirkularität',
            flameRetardancy: 'Flammhemmung',
            tribology: 'Tribologie',
            viscosityAndRheologyModification: 'Steuerung von Viskosität und Rheologie ',
            heatManagement: 'Wärmemanagement',
            colourAndEffectPigmentation: 'Farbe und Effektpigmentierung',
            durability: 'Dauerhaftigkeit',
            uvProtection: 'UV-Schutz',
            mechanicalImprovement: 'Mechanische Verbesserung',
            electricalProperties: 'Elektrische Eigenschaften',
            airEntrainingAgent: 'Luftporenbildner',
            shrinkageControl: 'Kontrolle der Schrumpfung',
            viscosityModification: 'Änderung der Viskosität',
            temperatureResistance: 'Temperaturbeständigkeit',
            enhancedElectricalProperties: 'Verbesserte elektrische Eigenschaften',
            rheologyModification: 'Modifizierung der Rheologie',
            abrasionAndScratchResistance: 'Abriebfestigkeit und Kratzfestigkeit',
            surfaceImprovement: 'Verbesserung der Oberflächenqualität',
            weightReduction: 'Gewichtsreduzierung',
            laserMarking: 'Laserbeschriftung',
            thermalConductivity: 'Thermische Leitfähigkeit',
            crosslinkingProperties: 'Vernetzungseigenschaften',
            coloursAndPigmentsForSpecialEffects: 'Farben & Pigmente für Spezialeffekte ',
            functionalFillersAndExtenders: 'Funktionelle Füllstoffe & Extender',
            laminatingAdhesives: 'Kaschierklebstoffe',
            opticalBrighteners: 'Optische Aufheller',
            thickeners: 'Verdickungsmittel',
            defoamers: 'Entschäumer',
            electricalConductivityAndInsulation: 'Elektrische Leitfähigkeit und Isolierung',
            mechanicalStrength: 'Mechanische Festigkeit',
            heatTransfer: 'Wärmeübertragung',
            electricalInsulation: 'Elektrische Isolierung',
            resistance: 'Widerstandsfähigkeit',
            dimensionalStability: 'Formbeständigkeit',
            rheologyImprovers: 'Rheologieverbesserer',
            easyProcessability: 'Leichte Verarbeitbarkeit',
            soundAbsorbing: 'Schalldämpfend',
        },
        industries: {
            paintsAndCoatings: {
                surfaceCoatings: 'Farben & Lacke',
                applicationsText: 'Dekorative Beschichtungen, Holzbeschichtungen, Industriebeschichtungen, ACE-Beschichtungen, Coil- und Can-Coating-Beschichtungen, Pulverbeschichtungen, Fahrzeugbeschichtungen, Schiffsbeschichtungen, Schutzbeschichtungen, Luft- und Raumfahrtbeschichtungen und Künstlerfarben, Hochleistungsbeschichtungen, Korrosionsschutzbeschichtungen, Straßenmarkierungsfarben, Spezialbeschichtungen',
                industryText: 'Die Fähigkeit von Beschichtungsexperten, schnell auf die Bedürfnisse ihrer Kunden zu reagieren, ist zu einem entscheidenden Erfolgsfaktor in der Branche geworden. Mit unseren innovativen und nachhaltigeren Lösungen für Farben und Beschichtungsformulierungen unterstützen wir unsere Kunden bei der Entwicklung intelligenter Lösungen für die verschiedensten Endverbraucher. Grolman bietet ein Portfolio von Spezialrohstoffen von erstklassigen Lieferanten, um die Bedürfnisse der Industrie zu erfüllen. Unsere Harze, Additive, Pigmente und Farbstoffe, Füllstoffe und Hochleistungsmaterialien für Farben und Lacke bieten die folgenden Funktionalitäten:',
            },
            adhesives: {
                adhesives: 'Klebstoffe',
                applicationsText: 'Bauwesen, Transport, Papier & Verpackung, Schuh und Leder, Holzverarbeitung und Schreinerei, Konsumgüter/DIY, Montagearbeiten, Klebebänder, Medizin',
                industryText1: 'Klebstoffe werden verwendet, um verschiedene Substrate bei unterschiedlichen Bedingungen zu verbinden. Daraus ergibt sich eine große Anzahl von Formulierungen, die den Anforderungen der jeweiligen Anwendung entsprechen müssen. Die Klebstoffindustrie ist stets bestrebt, die Technologien zu verbessern, um ihren Kunden die optimale Lösungen anzubieten und die höchste Qualitätsstandards zu erfüllen. Um dies zu erreichen, ist es von entscheidender Bedeutung, dass man genau weiß, wie die verschiedenen Rohstoffe zusammenwirken und wie sie sich das Zusammenspeil auf die Formulierung und die Leistung eines Klebstoffs auswirken. Hier können Sie auf unser technisches Know-how bei der Formulierung maßgeschneiderter Lösungen für eine Vielzahl von Anwendungen vertrauen.',
                industryText2: 'Unsere Harze, Additive, Pigmente und Farbstoffe, Füllstoffe und Hochleistungsmaterialien für Klebstoffe bieten die folgenden Funktionalitäten:',
            },
            printingInksAndGraphicArts: {
                printingInk: 'Druckfarben & Grafische Industrie',
                marketSegmentsText: 'Digitaldruckfarben, Flexodruckfarben, Tiefdruckfarben, Lithodruckfarben, Offsetdruckfarben, Siebdruckfarben, Sicherheitsdruckfarben, UV-Farben, Schreibprodukte.',
                industryText: 'Druckfarben haben andere Anforderungen als Farben und Lacke, da sie in Form einer sehr dünnen Schicht aufgetragen werden. Die visuellen Eigenschaften der Druckfarbe hängen von drei Hauptfaktoren ab: Farbe, Transparenz und Glanz. Unser Team unterstützt Sie gerne bei der Suche nach den optimalen Rohstoffen und der besten Zusammensetzung für Ihre Farbrezepturen. Unsere Harze, Additive, Pigmente und Farbstoffe, Füllstoffe und Performance-Materialien für Druckfarben und die grafische Industrie bieten die folgenden Funktionalitäten:',
            },
            rubber: {
                rubber: 'Gummi',
                applicationText: 'Kautschukmischungen zum Extrudieren, Kalandrieren, Beschichten, Formen und Gießen',
                industryText1: 'Durch den Einsatz maßgeschneiderter Spezialchemikalien wird aus dem Rohstoff Kautschuk ein Hochleistungsprodukt. Durch die Auswahl des jeweiligen Kautschuktyps sowie der Vernetzungschemikalien und Additive erhält das Endprodukt die gewünschten technischen Eigenschaften - sei es zur Verbesserung der Haltbarkeit, der Kraftstoffeffizienz, der Leistungsfähigkeit etc.',
                industryText2: 'Grolman bietet individuelle und innovative Produktlösungen für die Gummiindustrie. Unsere Harze, Additive, Pigmente und Farbstoffe, Füllstoffe und Performance-Materialien für Gummi bieten folgende Funktionalitäten:',
            },
            construction: {
                construction: 'Bauchemie',
                applicationText: 'Spezialisierte Betonzusatzmittel, Betonvorfertigung & Transportbeton, Trocken- und Nasszementmörtel, Spezialanstriche und Versiegelungsanstriche, Modifizierungsmittel für Straßenasphalt, Innovative Nano-Zusatzstoffe',
                industryText1: 'Wir unterstützen Sie gerne bei der Entwicklung von Produkten für Anwendungen im Bauwesen, wie z.B. Architektur- und Konstruktionsbetone, Betonpflastersteinen, großformatige Betonfertigteile, Trocken- und Nasszementmörtel, Fußböden, Dämmstoffe, Dächer, Wände, Fassaden, Verbundwerkstoffe und mehr. Mit Hilfe unserer Produkte können Gebäude haltbarer gemacht werden und benötigen weniger Ressourcen für die Instandhaltung. Unsere Spezialchemikalien helfen Gebäude energieeffizienter zu machen und schützen so unsere Umwelt.',
                industryText2: 'Grolman bietet ein Portfolio von speziellen Rohstoffen für unterschiedliche Anforderungen im Bereich der Baustoffindustrie.',
            },
            plastics: {
                plastics: 'Kunststoffe',
                applicationText: 'Thermoplastisches Compound / Extrusion, Thermoplastisches Masterbatch, Thermoplastische Verbundwerkstoffe, Duroplast, Duroplastische Verbundwerkstoffe, Elastomere',
                industryText1: 'Die Vermeidung von Kunststoffabfällen, die Verwendung umweltfreundlicher Materialien, die effiziente Nutzung von Ressourcen und Energie sowie die Verbesserung der Lebensqualität: In einer Zeit des ständigen Wandels ist die Kunststoffindustrie ständig gefordert, die nächste Innovation hervorzubringen. In einer Zeit, in der Verbraucher und Kommunen immer mehr verlangen, werden neue, nachhaltige und leistungsstarke Materialien von entscheidender Bedeutung sein.',
                industryText2: 'Wir von Grolman unterstützen Sie mit unserer Materialexpertise und einem außergewöhnlichen Marktverständnis, um die Spezialkunststoffe zu entwerfen, zu entwickeln und zu produzieren, die die Bedürfnisse Ihrer Kunden erfüllen. Unsere Harze, Additive, Pigmente, Farbstoffe , Füllstoffe und Performance-Materialien für Kunststoffe bieten die folgenden Funktionalitäten:',
            },        
            paper: {
                paper: 'Papier',
                applicationText: 'Beschichtetes grafisches Papier, Spezialpapier, Dekorpapier (vorimprägniert), Papier für technische Laminate, Banknoten- und Sicherheitspapier, Kohlepapier, Reibepapier, Technisches Papier für die Industrie',
                industryText1: 'Wir unterstützen Papierhersteller dabei, die heutigen Anforderungen zu erfüllen.',
                industryText2: 'Unsere innovativen Lösungen helfen, die Produktivität und Nachhaltigkeit zu steigern, die Gesamtbetriebskosten zu senken und die Qualitätsanforderungen zu erfüllen.',
                industryText3: 'Unsere Harze und Additive, Pigmente und Farbstoffe, Füllstoffe und Hochleistungsmaterialien erfüllen die Anforderungen der Zellstoff- und Papierindustrie.',
                productsAndProperties: 'Produkte & Eigenschaften:',
            },
            sealants: {
                sealants: 'Dichtstoffe',
                applicationText: 'Bauwesen, Transportwesen, Konsumgüter/DIY, Montagearbeiten',
                industryText: 'Dichtstoffe sind weiche, elastische Materialien, die Risse verschließen oder Fugen abdichten, um das Eindringen von Flüssigkeiten, Gasen oder Stäuben zu verhindern. Hierzu muss der Dichstoffe adhäsive und kohäsive Eigenschaften besitzen, sowie eine langfristige Funktionalität gewährleisten. Dies sind nur drei Beispiele aus einer Vielzahl von zum Teil sehr unterschiedlicher Anforderungen, die spezielle Lösungen für die individuellen Bedürfnisse verlangen.  Die technischen Experten von Grolman helfen Ihnen gerne, die geeigneten Rohstoffe für Ihre spezifische Anwendung zu finden. Unsere Harze, Additive, Pigmente und Farbstoffe, Füllstoffe und Hochleistungsmaterialien für Dichtstoffe bieten die folgenden Funktionalitäten:',
            }
        },
    },
    // Polish
    pl:
    {
        languageNo: 60,
        languageName: 'Polski',
        flag:'pl',
        optOut: {
            dataPrivacyInformation: 'Data privacy information',
            tracking: 'Tracking',
            weUseCookiesNote: 'We use cookies to enable you to make the best possible use of our website. Cookies necessary for operating the website are set. You can also allow cookies for statistics purposes. You can change privacy settings or directly allow all cookies.',
            changePrivacySettings: 'Change privacy settings',
            acceptAllCookies: 'Yes, I accept all cookies',
            matomoCookieAndTrackingExplanation: 'We use the open source software tool Matomo on our website. Matomo does not transfer any data to servers outside the control of Gustav Grolman GmbH & Co. KG. The tool uses cookies that allow us to count the number of visits. These text files are saved on your computer and enable the Gustav Grolman GmbH & Co. KG to analyse how its website is used. For us, your IP address is an anonymous code. This means we have no technical means of identifying you as a registered user. You will remain anonymous as a user. If you agree to the analysis of your data, please activate this cookie.',
            acceptChanges: 'Save changes',
            allowAll: 'Allow all',
            linkToPrivacyStatement: 'Click here for the privacy policy',
            allowTracking: 'Allow Tracking',
        },
    },
    // Spanish
    es:
    {
        languageNo: 11,
        languageName: 'Español',
        flag:'es',
        optOut: {
            dataPrivacyInformation: 'Aviso de protección de datos',
            tracking: 'Seguimiento',
            weUseCookiesNote: 'Utilizamos cookies para permitirle utilizar nuestro sitio web de manera óptima. Se establecen las cookies que son necesarias para el funcionamiento del sitio. También puede permitir cookies con fines estadísticos. Puede ajustar la configuración de protección de datos o aceptar todas las cookies directamente.',
            changePrivacySettings: 'Ajustar la configuración de privacidad',
            acceptAllCookies: 'Sí, acepto todas las cookies.',
            matomoCookieAndTrackingExplanation: 'En nuestro sitio web utilizamos la herramienta de software de código abierto Matomo. Matomo no transmite datos a servidores fuera del control de Gustav Grolman GmbH & Co. KG. La herramienta utiliza cookies. Con estas cookies podemos contabilizar las visitas. Estos archivos de texto se guardan en su computadora y permiten que la Gustav Grolman GmbH & Co. KG analice el uso de su sitio web. Su dirección IP es un identificador anónimo para nosotros; No disponemos de medios técnicos para identificarle como usuario registrado. Usted permanece como usuario anónimo. Si acepta la evaluación de sus datos, active esta cookie.',
            acceptChanges: 'Guardar los cambios',
            allowAll: 'Permitir todo',
            linkToPrivacyStatement: 'Haga clic aquí para ver la política de privacidad',
            allowTracking: 'Permitir el seguimiento',
        },
    },
    // Italian
    it:
    {
        languageNo: 5,
        languageName: 'Italiano',
        flag:'it',
        optOut: {
            dataPrivacyInformation: 'Data privacy information',
            tracking: 'Tracking',
            weUseCookiesNote: 'We use cookies to enable you to make the best possible use of our website. Cookies necessary for operating the website are set. You can also allow cookies for statistics purposes. You can change privacy settings or directly allow all cookies.',
            changePrivacySettings: 'Change privacy settings',
            acceptAllCookies: 'Yes, I accept all cookies',
            matomoCookieAndTrackingExplanation: 'We use the open source software tool Matomo on our website. Matomo does not transfer any data to servers outside the control of Gustav Grolman GmbH & Co. KG. The tool uses cookies that allow us to count the number of visits. These text files are saved on your computer and enable the Gustav Grolman GmbH & Co. KG to analyse how its website is used. For us, your IP address is an anonymous code. This means we have no technical means of identifying you as a registered user. You will remain anonymous as a user. If you agree to the analysis of your data, please activate this cookie.',
            acceptChanges: 'Save changes',
            allowAll: 'Allow all',
            linkToPrivacyStatement: 'Click here for the privacy policy',
            allowTracking: 'Allow Tracking',
        },
    },
    // French
    fr:
    {
        languageNo: 1,
        languageName: 'Français',
        flag:'fr',
        optOut: {
            dataPrivacyInformation: 'Note d’information sur la protection des données',
            tracking: 'Suivi',
            weUseCookiesNote: 'Nous utilisons des cookies pour vous garantir la meilleure expérience sur notre site. Certains cookies, nécessaires, doivent être activés pour assurer un fonctionnement optimal. Par ailleurs, vous pouvez autoriser des cookies à des fins statistiques. Vous pouvez modifier les paramètres de confidentialité ou approuver directement tous les cookies.',
            changePrivacySettings: 'Modifier les paramètres de confidentialité',
            acceptAllCookies: 'Oui, j’accepte tous les cookies',
            matomoCookieAndTrackingExplanation: 'Sur notre site Internet, nous utilisons le logiciel open source Matomo. Avec ce logiciel, aucune donnée n’est transmise à des serveurs échappant au contrôle de Gustav Grolman GmbH & Co. KG. L’outil utilise des cookies qui nous permettent de compter le nombre de visiteurs. Ces fichiers texte sont enregistrés sur votre ordinateur et permettent au Gustav Grolman GmbH & Co. KG d’analyser l’utilisation de son site. Votre adresse IP est pour nous un identifiant anonyme ; nous n’avons donc aucun moyen technique de vous identifier par ce biais en tant qu’utilisateur connecté. Vous conservez donc votre anonymat en tant qu’utilisateur. Si vous consentez à ce que vos données soient analysées, veuillez activer ce cookie.',
            acceptChanges: 'Enregistrer les modifications',
            allowAll: 'Autoriser tous',
            linkToPrivacyStatement: 'Lien vers la Déclaration sur la protection des données',
            allowTracking: 'Permettre le suivi',
        },
    }
}
export default language;